import BasePlugin from '../BasePlugin'

export default class AutocompleteInvestmentPrograms extends BasePlugin {
  async execute () {
    let data = {
      'recordId': this.context.getModel().id,
      'registryId': 87
    }
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AutocompleteInvestmentPrograms`,
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  }
}
